<template>
  <div class="boss">
    <!-- <van-nav-bar :title="name" left-arrow fixed @click-left="onClickLeft" /> -->
    <div class="content" v-html="xieyi"></div>
  </div>
</template>

<script>
import { getXieyi } from "../../api/index";
export default {
  data() {
    return {
      xieyi: "",
      name: "",
    };
  },
  created() {
    this.Xieyi();
  },
  methods: {
    async Xieyi() {
      let res = await getXieyi({ type: this.$route.query.type?this.$route.query.type:102 });
      if (res.data.code != 200) return;
      this.xieyi = res.data.data.content;
      this.name = res.data.data.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.boss {
  min-height: 100vh;
  width: 100vw;
  .van-nav-bar {
    height: 1rem;
    line-height: 1rem;
    /deep/ .van-nav-bar__content {
      height: 1rem;
    }
    /deep/ .van-nav-bar__title {
      font-size: 0.4rem;
    }
    /deep/ .van-icon {
      font-size: 0.5rem;
      color: #999;
    }
  }
  .content {
    padding: 0.5rem 0.3rem;
  }
}
</style>
